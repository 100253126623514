<template>
  <div>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-requests/offers',
      singularName: 'real-estate-requests/offers',
      searchFields: null,
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
